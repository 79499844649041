<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle  class="text-center">
              <div class="text-center">
                <h5 class="text-white font-weight-bold"><i class="ri-edit-line"></i> {{ $t('irriPumpInformation.construction_details_entry') }}</h5>
              </div>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <div class="border border-gray mb-3">
                    <b-row class="p-2">
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="organization"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="constructionDetailsEntry.org_id"
                              :options="organizationList"
                              id="org_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Division" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="division_id"
                            slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                              </template>
                            <b-form-select
                            plain
                            v-model="constructionDetailsEntry.division_id"
                            :options="divisionList"
                            id="division_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="District" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                plain
                                v-model="constructionDetailsEntry.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Upazila" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="upazila_id"
                            slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                              </template>
                            <b-form-select
                            plain
                            v-model="constructionDetailsEntry.upazilla_id"
                            :options="upazilaList"
                            id="upazila_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Union" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="union_id"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                plain
                                v-model="constructionDetailsEntry.union_id"
                                :options="unionList"
                                id="union_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="engineer name en" vid="engineer_name">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="engineer_name"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.engineer_name_en') }}
                          </template>
                          <b-form-input
                            id="engineer_name"
                            v-model="constructionDetailsEntry.engineer_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="drilling contractor name en" vid="drilling_contractor_name">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="drilling_contractor_name"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.drilling_contractor_name_en') }}
                          </template>
                          <b-form-input
                            id="drilling_contractor_name"
                            v-model="constructionDetailsEntry.drilling_contractor_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="project" vid="project_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="project_id"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irriPumpInformation.project')}} <span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                plain
                                v-model="constructionDetailsEntry.project_id"
                                :options="projectList"
                                id="project_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Pump Id" vid="pump_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="pump_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('irriPumpInformation.pump_id') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="constructionDetailsEntry.pump_id"
                                :options="pumpDropdownList"
                                id="pump_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Mouza No" vid='mouza_no' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="mouza_no"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                  {{ $t('irriPumpInformation.mouza_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="mouza_no"
                                v-model="constructionDetailsEntry.mouza_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                          <ValidationProvider name="Jl No" vid='jl_no' rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="jl_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.jl_no')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                              id="jl_no"
                              v-model="constructionDetailsEntry.jl_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Plot No" vid='plot_no' rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="plot_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.plot_no')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                              id="plot_no"
                              v-model="constructionDetailsEntry.plot_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="well No" vid="well_no" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="well_no"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.well_no') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="well_no"
                            v-model="constructionDetailsEntry.well_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="engineer name Bn" vid="engineer_name_bn">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="engineer_name_bn"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.engineer_name_bn') }}
                          </template>
                          <b-form-input
                            id="engineer_name_bn"
                            v-model="constructionDetailsEntry.engineer_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="drilling contractor name bn" vid="drilling_contractor_name_bn">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="drilling_contractor_name_bn"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.drilling_contractor_name_bn') }}
                          </template>
                          <b-form-input
                            id="drilling_contractor_name_bn"
                            v-model="constructionDetailsEntry.drilling_contractor_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border border-gray mb-3">
                    <b-row>
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize text-center bg-dark p-1">{{ $t('irriPumpInformation.operation') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="drilling start time" vid="drilling_start_time">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="drilling_start_time"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.drilling_started') }}
                          </template>
                          <b-form-input class="form-control"
                            type="time"
                            v-model="constructionDetailsEntry.drilling_start_time"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="drilling omplete time" vid="drilling_complete_time">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="drilling_complete_time"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.drilling_completed') }}
                          </template>
                          <b-form-input class="form-control"
                            type="time"
                            v-model="constructionDetailsEntry.drilling_complete_time"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="casing started" vid="casing_start_time">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="casing_start_time"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.casing_started') }}
                          </template>
                          <b-form-input class="form-control"
                            type="time"
                            v-model="constructionDetailsEntry.casing_start_time"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="casing completed" vid="casing_complete_time">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="casing_complete_time"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.casing_completed') }}
                          </template>
                          <b-form-input class="form-control"
                            type="time"
                            v-model="constructionDetailsEntry.casing_complete_time"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="gravel placing started" vid="gravel_placing_start_time">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="gravel_placing_start_time"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.gravel_placing_started') }}
                          </template>
                          <b-form-input class="form-control"
                            type="time"
                            v-model="constructionDetailsEntry.gravel_placing_start_time"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="gravel placing completed" vid="gravel_placing_complete_time">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="gravel_placing_complete_time"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.gravel_placing_completed') }}
                          </template>
                          <b-form-input class="form-control"
                            type="time"
                            v-model="constructionDetailsEntry.gravel_placing_complete_time"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="drilling_diameter" vid="drilling_diameter" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="drilling_diameter"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irriPumpInformation.drilling_diameter')}}
                                  </template>
                                <b-form-select
                                plain
                                v-model="constructionDetailsEntry.drilling_diameter"
                                :options="drillingDiameterList"
                                id="drilling_diameter"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border border-gray mb-3">
                    <b-row>
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize text-center bg-dark p-1">{{ $t('irriPumpInformation.housing_pipe_dia') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="UWC above GL" vid="uwc_above_gl" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="uwc_above_gl"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irriPumpInformation.UWC_above_GL')}}
                                  </template>
                                <b-form-input
                                plain
                                type="number"
                                min="0"
                                v-model="constructionDetailsEntry.uwc_above_gl"
                                id="uwc_above_gl"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="UWC below GL" vid="uwc_below_gl" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="uwc_below_gl"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irriPumpInformation.UWC_below_GL')}}
                                  </template>
                                <b-form-input
                                plain
                                type="number"
                                min="0"
                                v-model="constructionDetailsEntry.uwc_below_gl"
                                id="uwc_below_gl"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="total" vid="uwc_total_gl" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="uwc_total_gl"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irriPumpInformation.total')}}
                                  </template>
                                <b-form-input
                                plain
                                type="number"
                                min="0"
                                v-model="constructionDetailsEntry.uwc_total_gl"
                                id="uwc_total_gl"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border border-gray mb-3">
                    <b-row>
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize text-center bg-dark p-1">{{ $t('irriPumpInformation.LWC') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.depth_below_GL') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="from" vid="lwc_from_gl">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="lwc_from_gl"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.from') }}
                          </template>
                          <b-form-input
                            id="lwc_from_gl"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.lwc_from_gl"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="to" vid="lwc_to_gl">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="lwc_to_gl"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.to') }}
                          </template>
                          <b-form-input
                            id="lwc_to_gl"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.lwc_to_gl"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="total" vid="lwc_total_gl" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="lwc_total_gl"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irriPumpInformation.total')}}
                                  </template>
                                <b-form-input
                                plain
                                type="number"
                                min="0"
                                v-model="constructionDetailsEntry.lwc_total_gl"
                                id="lwc_total_gl"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border border-gray mb-3">
                    <b-row>
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize text-center bg-dark p-1">{{ $t('irriPumpInformation.screen_dia') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.depth_below_GL') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="from" vid="screen_from_gl">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="screen_from_gl"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.from') }}
                          </template>
                          <b-form-input
                            id="screen_from_gl"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.screen_from_gl"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="to" vid="screen_to_gl">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="screen_to_gl"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.to') }}
                          </template>
                          <b-form-input
                            id="screen_to_gl"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.screen_to_gl"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="total" vid="screen_total_gl" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="screen_total_gl"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irriPumpInformation.total')}}
                                  </template>
                                <b-form-input
                                plain
                                type="number"
                                min="0"
                                v-model="constructionDetailsEntry.screen_total_gl"
                                id="screen_total_gl"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border border-gray mb-3">
                    <b-row>
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize text-center bg-dark p-1">{{ $t('irriPumpInformation.bail_plug') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.depth_below_GL') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="from" vid="bail_plug_from_gl">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="bail_plug_from_gl"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.from') }}
                          </template>
                          <b-form-input
                            id="bail_plug_from_gl"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.bail_plug_from_gl"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="to" vid="bail_plug_to_gl">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="bail_plug_to_gl"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.to') }}
                          </template>
                          <b-form-input
                            id="bail_plug_to_gl"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.bail_plug_to_gl"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border border-gray mb-3">
                    <b-row>
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize text-center bg-dark p-1">{{ $t('irriPumpInformation.centralizes') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="8" sm="12">
                        <ValidationProvider name="from" vid="centralize_description">
                          <b-form-group
                            class="row"
                            label-cols-sm="2"
                            label-for="centralize_description"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.description') }}
                          </template>
                          <b-form-textarea
                            id="centralize_description"
                            v-model="constructionDetailsEntry.centralize_description"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="total_number" vid="centralize_total">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="centralize_total"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.total_number') }}
                          </template>
                          <b-form-input
                            id="centralize_total"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.centralize_total"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border border-gray mb-3">
                    <b-row>
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize text-center bg-dark p-1">{{ $t('irriPumpInformation.gravel') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.depth_below_GL') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="from" vid="gravel_depth_form">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="gravel_depth_form"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.from') }}
                          </template>
                          <b-form-input
                            id="gravel_depth_form"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.gravel_depth_form"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="to" vid="gravel_depth_to">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="gravel_depth_to"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.to') }}
                          </template>
                          <b-form-input
                            id="gravel_depth_to"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.gravel_depth_to"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="fixture length" vid="fixture_length">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fixture_length"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.fixture_length') }}
                          </template>
                          <b-form-input
                            id="fixture_length"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.fixture_length"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="4">
                        <p class="text-black">{{ $t('irriPumpInformation.from_up_to_UWC') }}</p>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border border-gray mb-3">
                    <b-row>
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize text-center bg-dark p-1">{{ $t('irriPumpInformation.verticality_test') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="5" sm="12">
                        <ValidationProvider name="max deviation" vid="max_deviation">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="max_deviation"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.max_deviation') }}
                          </template>
                          <b-form-input
                            id="max_deviation"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.max_deviation"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="5" sm="12">
                        <ValidationProvider name="inch at" vid="max_depth">
                          <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="max_depth"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.inch_at') }}
                          </template>
                          <b-form-input
                            id="max_depth"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.max_depth"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="2" sm="4">
                        <p class="text-black">{{ $t('irriPumpInformation.depth') }}</p>
                      </b-col>
                      <b-col lg="5" sm="12">
                        <ValidationProvider name="date" vid="date">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="date"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.date') }}
                          </template>
                          <b-form-input class="form-control"
                            v-model="constructionDetailsEntry.date"
                            :placeholder="$t('globalTrans.select_date')"
                            id="datepicker"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="7" sm="12">
                        <ValidationProvider name="cross section of the well" vid="cross_section_of_well">
                          <b-form-group
                            class="row"
                            label-cols-sm="2"
                            label-for="cross_section_of_well"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.cross_section_of_the_well') }}
                          </template>
                          <b-form-textarea
                            id="cross_section_of_well"
                            v-model="constructionDetailsEntry.cross_section_of_well"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="5" sm="12">
                        <ValidationProvider name="ordered depth" vid="ordered_depth">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="ordered_depth"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.ordered_depth') }}
                          </template>
                          <b-form-input
                            id="ordered_depth"
                            type="number"
                            min="0"
                            v-model="constructionDetailsEntry.ordered_depth"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row class="mt-3">
                      <b-col></b-col>
                      <b-col>
                        <button  class="btn btn-primary btn-block" type="submit">{{ saveBtnName }}</button>
                      </b-col>
                      <b-col></b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { constructionDetailsStore, constructionDetailsUpdate, constructionDetailsShow } from '../../api/routes'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
  prop: ['id', 'item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  async created () {
    this.authUserData = this.authUser
    const id = this.$route.query.id
    if (id) {
      this.getData()
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.constructionDetailsEntry = Object.assign({}, this.constructionDetailsEntry, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.constructionDetailsEntry = Object.assign({}, this.constructionDetailsEntry, {
        org_id: this.authUser.office_detail.org_id,
        division_id: this.authUser.office_detail.division_id,
        district_id: this.authUser.office_detail.district_id,
        upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
  },
  data () {
    return {
      authUserData: {},
      schemeTypeListData: [],
      loading: false,
      constructionDetailsEntry: {
        org_id: '15',
        pump_id: '0',
        division_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0',
        mouza_no: '',
        jl_no: '',
        project_id: '0',
        well_no: '',
        engineer_name: '',
        engineer_name_bn: '',
        drilling_contractor_name: '',
        drilling_contractor_name_bn: '',
        drilling_start_date: '',
        drilling_start_time: '',
        drilling_complete_date: '',
        drilling_complete_time: '',
        casing_start_date: '',
        casing_start_time: '',
        casing_complete_date: '',
        casing_complete_time: '',
        gravel_placing_start_date: '',
        gravel_placing_start_time: '',
        gravel_placing_complete_date: '',
        gravel_placing_complete_time: '',
        drilling_diameter: '',
        housing_pipe: '',
        uwc_above_gl: '',
        uwc_below_gl: '',
        uwc_total_gl: '',
        lwc_dia: '',
        lwc_from_gl: '',
        lwc_to_gl: '',
        lwc_total_gl: '',
        screen_dia: '',
        screen_from_gl: '',
        screen_to_gl: '',
        screen_total_gl: '',
        bail_plug_dia: '',
        bail_plug_from_gl: '',
        bail_plug_to_gl: '',
        bail_plug_total_gl: '',
        centralize_description: '',
        centralize_total: '',
        gravel_quantity: '',
        gravel_depth_form: '',
        gravel_depth_to: '',
        fixture_length: '',
        max_deviation: '',
        max_depth: '',
        date: '',
        cross_section_of_well: '',
        ordered_depth: ''
      },
      drilling_diameters: [
        {
          value: 14,
          text: '14',
          text_bn: '১৪'
        },
        {
          value: 22,
          text: '22',
          text_bn: '২২'
        },
        {
          value: 24,
          text: '24',
          text_bn: '২৪'
        },
        {
          value: 44,
          text: '44',
          text_bn: '৪৪'
        }
      ],
      pumpInformationList: [],
      pumpInfo: {},
      pumpDropdownList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      schManDistrictList: [],
      schManUpazilaList: [],
      schManUnionList: [],
      pumpDistrictList: [],
      pumpUpazilaList: [],
      pumpUnionList: [],
      farmer_id: ''
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    saveBtnName () {
      return this.farmer_id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    drillingDiameterList: function () {
      return this.drilling_diameters.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text }
        }
      })
    },
    projectList () {
      const projectList = this.$store.state.IrriConfig.commonObj.projectList
      return projectList.filter(project => project.org_id === 15)
    }
  },
  watch: {
    'constructionDetailsEntry.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictList(newVal)
      }
    },
    'constructionDetailsEntry.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
      }
    },
    'constructionDetailsEntry.upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.unionList = this.getUnionList(newVal)
        this.pumpDropdownList = this.getPumplist(newVal)
      }
    },
    'constructionDetailsEntry.union_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pumpDropdownList = this.getPumplist(newVal)
      }
    },
    'constructionDetailsEntry.pump_id': function (newVal, oldVal) {
       this.getpumpSingleInfo(newVal)
    }
  },
  methods: {
    getData () {
     RestApi.getData(irriSchemeServiceBaseUrl, constructionDetailsShow + this.$route.query.id).then(response => {
        this.constructionDetailsEntry = response.data
      })
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.$route.query.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${constructionDetailsUpdate}/${this.$route.query.id}`, this.constructionDetailsEntry)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, constructionDetailsStore, this.constructionDetailsEntry)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false
      if (result.success) {
          this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$route.query.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          setTimeout(async () => {
            this.$router.push('/irrigation/pump-information/construction-details')
          }, 500)
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getPumplist (upazillaOrUnionId) {
      this.pumpInformationList = this.$store.state.IrriConfig.commonObj.pumpInfoList.filter(pumpInfo => (pumpInfo.upazilla_id === upazillaOrUnionId || pumpInfo.union_id === upazillaOrUnionId) && pumpInfo.status === 0)
      return this.pumpInformationList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.pump_id }
        } else {
          return { value: obj.id, text: obj.pump_id }
        }
      })
    },
    getpumpSingleInfo (pumpId) {
      this.pumpInfo = this.pumpInformationList.find(pumpInfo => pumpInfo.id === pumpId)
      this.constructionDetailsEntry.mouza_no = this.pumpInfo.mouza_no
      this.constructionDetailsEntry.jl_no = this.pumpInfo.jl_no
      this.constructionDetailsEntry.plot_no = this.pumpInfo.plot_no
    }
  }
}
</script>
